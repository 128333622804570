.sb-wrapper{
    background-color: #f9f9f9;
    overflow-y: hidden;
}
.boxes{
    float:right;
}
.spacer{
    height: 180px;
}
.header{
    font-family: ComoW01Bold;
    font-weight: 500;
    font-size:45px!important;
    color: #575656;
    line-height: 1.2;
    padding-left: 40px;
    margin-bottom: 300px;
}
.sb-middle ul{
    position: relative;
    top: -8px;
    list-style-type: none;
    font-size: 35px !important;
}
.sb-middle ul li:not(:last-child) { 
    margin-bottom: 20px;  
 }
.sidebarImg{
    width: 85%;
    position: absolute;
    top: 75%;
    left: 50%;
    -ms-transform: translate(-50%, -75%);
    transform: translate(-50%, -75%);
}
.sbfooter{
    font-size: 22px;
    position: absolute;
    top: 99%;
    left: 50%;
    -ms-transform: translate(-50%, -99%);
    transform: translate(-50%, -99%);
}
.sbfooter ul li:not(:last-child){
    margin-right:20px;
}
a:link {
    text-decoration: none !important;
    color: #575656;
  }
  
  a:visited {
    text-decoration: none !important;
    color: #575656;
  }
  
  a:hover {
    text-decoration: none !important;
    color: #575656;
  }
  
  a:active {
    text-decoration: none !important;
    color: #575656;
  }
  .successtext{
      font-size:18px;
  }