.container{
    margin: 0 !important;
}
body{
    height: 100vh;
    min-height: 700px;
    width: 100%;
    color: #575656 !important;
    font-family:"ComoW01Regular" !important;
}
#root{
    height:100%;
    width:100%;
}
#root > div{
    height: 100%;
}
.tf_email{
    margin-left: 10px;
}
input:read-only {
    background-color: lightgray;
}
img{
    margin-bottom: 15px;
}
input{
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: .65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

input[type="submit"], .btn-primary
{
    appearance: auto;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: hand;
    box-sizing: border-box;
    font: 400 13.3333px Arial;
    font-weight: 400;
    color: #212529;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .65rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-color: #23bade;
    border-color: #23bade;
    color: #fff;

}
.content-wrapper{
    width: 100%;
    max-width: 500px;
    margin: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}
.f-content{
    width: 100%;
    max-width: 600px;
    min-width: 400px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    font-family:ComoW01Regular;
}
.f-content .form-label{
    margin-bottom: 0px !important;
}
.f-content input{
    height: calc(1.5em + 1.3rem + 2px);
}
.f-content .form-group{
    margin-bottom: 30px;
}
.descr{
    font-family: ComoW01Regular;
    font-size:35px;
    font-weight: 500;
 }
 .success{
     max-width: 700px !important;
 }